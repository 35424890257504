import { useEffect, useCallback, useRef } from "react";
// import firebase from "@firebase/app";
// import { useEffect } from "react";
import firebase from "firebase/compat/app";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
const LAST_ACTIVITY_KEY = "lastActivityTime";
const EVENTS = ["mousedown", "keydown", "scroll", "touchstart", "wheel", "click"];
const DEFAULT_TIMEOUT = 2 * 60 * 60 * 1000; // 10 minutes

const useIdleLogout = (timeout = DEFAULT_TIMEOUT) => {
  const timerRef = useRef(null);
  const authUnsubscribe = useRef(null);
  const lastActivityRef = useRef(Date.now());

  const isUserLoggedIn = useCallback(() => !!localStorage.getItem("user_id"), []);

  const performLogout = useCallback(() => {
    localStorage.clear();
    firebase.auth().signOut().then(() => {
      window.location.href = "/signin";
    });
  }, []);

  const handleLogout = useCallback(async () => {
    const result = await MySwal.fire({
      title: "Session Expired",
      text: "You have been logged out due to inactivity.",
      icon: "warning",
      confirmButtonText: "OK",
      allowOutsideClick: false,
    });

    if (result.isConfirmed) performLogout();
  }, [performLogout]);

  const checkInactivity = useCallback(() => {
    const storedTime = parseInt(localStorage.getItem(LAST_ACTIVITY_KEY), 10);
    const currentTime = Date.now();
    
    if (currentTime - storedTime >= timeout) {
      handleLogout();
      return true;
    }
    return false;
  }, [timeout, handleLogout]);

  const resetTimer = useCallback(() => {
    const now = Date.now();
    localStorage.setItem(LAST_ACTIVITY_KEY, now.toString());
    lastActivityRef.current = now;

    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(checkInactivity, timeout);
  }, [timeout, checkInactivity]);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === "visible") {
      if (!checkInactivity()) {
        resetTimer();
      }
    }
  }, [checkInactivity, resetTimer]);

  useEffect(() => {
    if (!isUserLoggedIn()) return;

    // Initialize with existing or new timestamp
    if (!localStorage.getItem(LAST_ACTIVITY_KEY)) {
      resetTimer();
    } else {
      if (!checkInactivity()) {
        const remaining = timeout - (Date.now() - parseInt(localStorage.getItem(LAST_ACTIVITY_KEY), 10));
        timerRef.current = setTimeout(checkInactivity, Math.max(remaining, 0));
      }
    }

    // Cross-tab synchronization
    const storageHandler = (e) => {
      if (e.key === LAST_ACTIVITY_KEY) {
        lastActivityRef.current = parseInt(e.newValue, 10);
      }
    };

    // Activity listeners
    const throttledReset = throttle(resetTimer, 5000);
    EVENTS.forEach(e => window.addEventListener(e, throttledReset, { passive: true }));
    window.addEventListener("storage", storageHandler);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Auth state cleanup
    authUnsubscribe.current = firebase.auth().onAuthStateChanged(user => {
      if (!user) {
        localStorage.removeItem(LAST_ACTIVITY_KEY);
        clearTimeout(timerRef.current);
      }
    });

    return () => {
      EVENTS.forEach(e => window.removeEventListener(e, throttledReset));
      window.removeEventListener("storage", storageHandler);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearTimeout(timerRef.current);
      authUnsubscribe.current?.();
    };
  }, [timeout, isUserLoggedIn, checkInactivity, resetTimer, handleVisibilityChange]);

  return null;
};

// Throttle utility function
const throttle = (fn, delay) => {
  let lastCall = 0;
  return (...args) => {
    const now = Date.now();
    if (now - lastCall < delay) return;
    lastCall = now;
    fn(...args);
  };
};

export default useIdleLogout;

// import { useEffect, useCallback, useRef } from "react";
// import firebase from "@firebase/app";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";

// const MySwal = withReactContent(Swal);
// const LAST_ACTIVITY_KEY = "lastActivityTime";
// const EVENTS = ["mousedown", "keydown", "scroll", "touchstart", "wheel", "click"];
// const DEFAULT_TIMEOUT = 1 * 60 * 1000; // 10 minutes

// const useIdleLogout = (timeout = DEFAULT_TIMEOUT) => {
//   const timerRef = useRef(null);
//   const authUnsubscribe = useRef(null);
//   const lastActivityRef = useRef(Date.now());

//   const isUserLoggedIn = useCallback(() => !!localStorage.getItem("user_id"), []);

//   const performLogout = useCallback(() => {
//     localStorage.clear();
//     firebase.auth().signOut().then(() => {
//       window.location.href = "/signin";
//     });
//   }, []);

//   const handleLogout = useCallback(async () => {
//     const result = await MySwal.fire({
//       title: "Session Expired",
//       text: "You have been logged out due to inactivity.",
//       icon: "warning",
//       confirmButtonText: "OK",
//       allowOutsideClick: false,
//     });

//     if (result.isConfirmed) performLogout();
//   }, [performLogout]);

//   // Cross-tab synchronization
//   const syncActivity = useCallback(() => {
//     const storedTime = parseInt(localStorage.getItem(LAST_ACTIVITY_KEY), 10);
//     if (storedTime > lastActivityRef.current) {
//       lastActivityRef.current = storedTime;
//       resetTimer();
//     }
//   }, []);

//   const resetTimer = useCallback(() => {
//     const now = Date.now();
//     lastActivityRef.current = now;
//     localStorage.setItem(LAST_ACTIVITY_KEY, now.toString());

//     if (timerRef.current) clearTimeout(timerRef.current);
//     timerRef.current = setTimeout(() => {
//       const elapsed = Date.now() - lastActivityRef.current;
//       if (elapsed >= timeout) handleLogout();
//     }, timeout);
//   }, [timeout, handleLogout]);

//   const handleVisibilityChange = useCallback(() => {
//     if (document.visibilityState === "visible") {
//       const storedTime = parseInt(localStorage.getItem(LAST_ACTIVITY_KEY), 10);
//       const elapsed = Date.now() - storedTime;
      
//       if (elapsed >= timeout) {
//         handleLogout();
//       } else {
//         timerRef.current = setTimeout(handleLogout, timeout - elapsed);
//       }
//     }
//   }, [timeout, handleLogout]);

//   useEffect(() => {
//     if (!isUserLoggedIn()) return;

//     // Initialize with current time
//     resetTimer();

//     // Add storage listener for cross-tab sync
//     const storageHandler = (e) => {
//       if (e.key === LAST_ACTIVITY_KEY) syncActivity();
//     };
//     window.addEventListener("storage", storageHandler);

//     // Add activity listeners
//     const throttledReset = throttle(resetTimer, 1000);
//     EVENTS.forEach(e => window.addEventListener(e, throttledReset, { passive: true }));
//     document.addEventListener("visibilitychange", handleVisibilityChange);

//     // Auth state listener
//     authUnsubscribe.current = firebase.auth().onAuthStateChanged(user => {
//       if (!user) {
//         localStorage.removeItem(LAST_ACTIVITY_KEY);
//         clearTimeout(timerRef.current);
//       }
//     });

//     return () => {
//       window.removeEventListener("storage", storageHandler);
//       EVENTS.forEach(e => window.removeEventListener(e, throttledReset));
//       document.removeEventListener("visibilitychange", handleVisibilityChange);
//       clearTimeout(timerRef.current);
//       authUnsubscribe.current?.();
//     };
//   }, [timeout, isUserLoggedIn, resetTimer, syncActivity, handleVisibilityChange]);

//   return null;
// };

// // Throttle utility (1 second)
// const throttle = (fn, delay) => {
//   let lastCall = 0;
//   return (...args) => {
//     const now = Date.now();
//     if (now - lastCall < delay) return;
//     lastCall = now;
//     fn(...args);
//   };
// };

// export default useIdleLogout;

// import { useEffect, useCallback, useRef } from "react";
// import firebase from "@firebase/app";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";

// const MySwal = withReactContent(Swal);
// const LAST_ACTIVITY_KEY = "lastActivityTime";
// const EVENTS = ["mousedown", "keydown", "scroll", "touchstart", "wheel", "click"];
// // const DEFAULT_TIMEOUT = 2 * 60 * 60 * 1000; // 2 hours
// const DEFAULT_TIMEOUT = 10 * 60 * 1000;

// const useIdleLogout = (timeout = DEFAULT_TIMEOUT) => {
//   const timerRef = useRef(null);
//   const authUnsubscribe = useRef(null);

//   const isUserLoggedIn = useCallback(() => !!localStorage.getItem("user_id"), []);

//   const performLogout = useCallback(() => {
//     localStorage.clear();
//     firebase.auth().signOut().then(() => {
//       window.location.href = "/signin";
//     });
//   }, []);

//   const handleLogout = useCallback(async () => {
//     const result = await MySwal.fire({
//       title: "Session Expired",
//       text: "You have been logged out due to inactivity.",
//       icon: "warning",
//       confirmButtonText: "OK",
//       allowOutsideClick: false,
//     });

//     if (result.isConfirmed) {
//       performLogout();
//     }
//   }, [performLogout]);


//   const resetTimer = useCallback(() => {
//     const now = Date.now();
//     localStorage.setItem(LAST_ACTIVITY_KEY, now.toString());

//     if (timerRef.current) clearTimeout(timerRef.current);
//     timerRef.current = setTimeout(() => {
//       const storedTime = parseInt(localStorage.getItem(LAST_ACTIVITY_KEY), 10);
//       if (Date.now() - storedTime >= timeout) handleLogout();
//     }, timeout);
//   }, [timeout, handleLogout]);

//   const handleVisibilityChange = useCallback(() => {
//     if (document.visibilityState === "visible") {
//       const storedTime = parseInt(localStorage.getItem(LAST_ACTIVITY_KEY), 10);
//       if (Date.now() - storedTime >= timeout) {
//         handleLogout();
//       } else {
//         resetTimer();
//       }
//     }
//   }, [timeout, handleLogout, resetTimer]);

//   useEffect(() => {
//     if (!isUserLoggedIn()) return;

//     // Initial setup
//     const storedTime = localStorage.getItem(LAST_ACTIVITY_KEY);
//     if (!storedTime) {
//       resetTimer();
//     } else {
//       const initialDelay = Math.max(timeout - (Date.now() - parseInt(storedTime, 10)), 0);
//       timerRef.current = setTimeout(handleLogout, initialDelay);
//     }

//     // Add event listeners for user activity
//     const throttledReset = throttle(resetTimer, 60000);
//     EVENTS.forEach(e => window.addEventListener(e, throttledReset, { passive: true }));
//     document.addEventListener("visibilitychange", handleVisibilityChange);

//     // Handle tab close/logout
//     const handleTabClose = () => {
//       localStorage.clear();
//       firebase.auth().signOut();
//     };
//     window.addEventListener("beforeunload", handleTabClose);

//     // Firebase auth state listener
//     authUnsubscribe.current = firebase.auth().onAuthStateChanged(user => {
//       if (!user) {
//         localStorage.removeItem(LAST_ACTIVITY_KEY);
//         clearTimeout(timerRef.current);
//       }
//     });

//     return () => {
//       EVENTS.forEach(e => window.removeEventListener(e, throttledReset));
//       document.removeEventListener("visibilitychange", handleVisibilityChange);
//       window.removeEventListener("beforeunload", handleTabClose);
//       clearTimeout(timerRef.current);
//       authUnsubscribe.current?.();
//     };
//   }, [timeout, isUserLoggedIn, handleLogout, resetTimer, handleVisibilityChange]);
// };

// // Utility function to throttle callbacks
// const throttle = (fn, delay) => {
//   let lastCall = 0;
//   return (...args) => {
//     const now = new Date().getTime();
//     if (now - lastCall < delay) return;
//     lastCall = now;
//     fn(...args);
//   };
// };

// export default useIdleLogout;

const initialState = {
    data: [],
    total: 1,
    params: {},
    schemes: [],
    proposals: [],
    circularsData: [],
    selectedApplicationData: [],
    headsData: [],
    installMentData: [],
    installMentAllDataVerify: [],
    installMentAllData: [],
    laterFormatData: [],
    updatedApplicationData: [],
    remarkData: [],
    wardDataByUser: [],
    penaltyData: [],
    paymentList: [],
    defUsers: [],


}

const landDefermentReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_DATA_DEMAND_NOTE':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }

        case 'CREATE_CIRCULAR':
            return { ...state }

        case 'UPDATE_CIRCULAR':
            return { ...state }

        case 'SET_SCHEMES_BY_DEVELOPER':
            return {
                ...state,
                schemes: action.payload,
            };
        case 'SET_PROPOSAL_BY_DEVELOPER':
            return {
                ...state,
                proposals: action.payload,
            };

        case 'GET_CIRCULAR_DATA':
            return {
                ...state,
                circularsData: action.data,
            };
        case 'GET_APPLICATION_DATA':
            return {
                ...state,
                selectedApplicationData: action.data,
            };
            case 'GET_HEAD_DATA':
            return {
                ...state,
                headsData: action.data,
            };
        case 'CREATE_INSTALLMENT':
            return {
                ...state,
                installMentData: action.data,
            }
        case 'GET_DATE_INSTALLMENT':
            return {
                ...state,
                installMentAllData: action.allData,
                installMentData: action.data,
                total: action.totalPages,
                params: action.params
            }

        case "GET_DATA_INSTALLMENT_VERIFY":
            return {
                ...state,
                installMentAllData: action.allData,
                installMentAllDataVerify: action.data,
                total: action.totalPages,
                params: action.params
            }

        case 'GET_LATER_FORMATE_BY_TYPE':
            return {
                ...state,
                laterFormatData: action.data,
            }
        case 'GET_DEFERMENT_APPLICATION_DATA':
            return {
                ...state,
                updatedApplicationData: action.data,
            }
        case 'GET_REMARK_FOR_DEF_APPLICATION':
            return {
                ...state,
                remarkData: action.data,
            }
        case 'GET_WARD_DATA_BY_USERID':
            return {
                ...state,
                wardDataByUser: action.data,
            }
        case "GET_DATA_FOR_PENALTY":
            return {
                ...state,
                penaltyData: action.data,
                total: action.totalPages,
                params: action.params
            }
        case "GET_INSTALLMENT_PAYMENT":
            return {
                ...state,
                paymentList: action.data
            } 

        case 'GET_DEF_USERS_DETAIL':
            return {
                ...state,
                defUsers: action.data,
            }
        default:
            return { ...state }
    }
}
export default landDefermentReducer
